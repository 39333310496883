body {
  background-color: #3b3b3b
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  height: 100%;
}

.tooltip {
  background-color: lime;
}

.columnResizer {
  background-color: white;
  width: 3px;
  min-width: 3px;
  max-width: 3px;
  padding: 0 0 0 0;
  border: none;
}

.mainButton {
  background-color: #cecece;
  margin-top: 30px;
  width: 90%;
  height: 10%;
  border-radius: 3px;
  border-bottom-width: 7px;
  border-color: #838383;
}

.actionButton {
  background-color: 'blue';
  border-radius: 3px;
  border-bottom-width: 7%;
  border-color: #838383;
}