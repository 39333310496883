.marker-pin {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 50px solid #ff5e2d;
    position: absolute;
    left: 18%;
}

.marker-circle {
    content: '';
    min-width: 40px;
    width: auto;
    height: 40px;
    background: #fff;
    position: absolute;
    left: 0%;
    bottom: 55%;
    border-radius: 50%;
    border-width: 5px;
    border-color: #ff5e2d;
    text-align: center;
}

.marker-text {
    /*font-size: 20px;*/
    font-size: 1.667em;
    /*padding-right: 2px;*/
    padding-right: 0.1em;
    word-wrap: normal;
}

.custom-div-icon i {
    position: absolute;
    width: 22px;
    font-size: 22px;
    left: 0;
    right: 0;
    margin: 10px auto;
    text-align: center;
}

.number-icon {
    background-image: url("./marker0.png");
    text-align: center;
    color: White;
}

.flag-pole {
    height: 40px;
    width: 3px;
    background-color: rgb(100, 100, 100);
}

.flag-start {
    position: absolute;
    left: 10%;
    bottom: 55%;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 17px solid black;
}

.flag-end {
    position: absolute;
    left: 10%;
    bottom: 55%;
    width: 0;
    height: 0;
    border-top: 8px solid black;
    border-bottom: 8px solid black;
    border-left: 7px solid black;
    border-right: 10px solid transparent;
}

.flag-id {
    position: absolute;
    left: 12%;
    bottom: 66%;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}