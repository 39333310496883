.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2px;
  border-radius: 5px;
  position: relative;
  max-width: 700px;
  min-width: 350px;
  max-height: 700px;
  min-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}